<template>
  <div class="hogwarts">
    <!-- cards -->
    <div class="cards horizontal justy-center " >
      <!-- card -->
      <div class="card vertical"  v-for="item in 13 " :key="item">
        <!-- 图片 -->
        <div class="image">
          <img src="https://fomantic-ui.com/images/avatar2/large/molly.png" alt="">
        </div>
        <!-- 卡片 -->
        <div class="content vertical">
          <div class="title">Matt Giampietro</div>
          <div class="sub-title">Matt Giampietro is an art director</div>
          <div class="desc">Matt Giampietro is an art director working in New York.</div>
          <div class="footer justy-end">10月12日开课</div>
        </div>
      </div>
      <div class="fills" v-for="item in 10" :key="item + 20"></div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.hogwarts{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}
.hogwarts .cards {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.hogwarts .card {
  width: 320px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 15px 20px;
  box-shadow: 0 1px 3px 0 #bcbdbd;
}
.hogwarts .card.horizontal {
  max-width: 400px;
  width: 400px;
  margin: 15px 20px;
  box-shadow: 0 1px 3px 0 #bcbdbd;
}
.hogwarts .card.vertical {
  width: 320px;
  margin: 15px 20px;
  box-shadow: 0 1px 3px 0 #bcbdbd;
}
.hogwarts .card:hover {
  cursor: pointer;
  z-index: 99;
  background: #fff;
  border: none;
  -webkit-box-shadow: 0 1px 3px 0 #bcbdbd;
  box-shadow: 0 1px 3px 0 #bcbdbd;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}
.hogwarts .cards .image {
  width: 40%;
  height: auto;
}
.hogwarts .card .image {
  width: 100%;
}
.hogwarts .card.vertical .image {
  width: 100%;
}
.hogwarts .card.horizontal .content {
  margin-left: 15px;
  padding: 5px 10px 0 0;
}
.hogwarts .card.vertical .content {
  width: 100%;
}
.hogwarts img {
  display: block;
  width: 100%;
  height: auto;
}
.hogwarts .content {
  width: 60%;
  font-size: 16px;
  padding: 7px 15px 2px;
  flex-grow: 1;
}
.hogwarts .footer {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  margin-top: 10px;
}
.hogwarts .vertical {
  display: flex;
  flex-direction: column;
}
.hogwarts .horizontal {
  display: flex;
  flex-direction: row;
}
.hogwarts .justy-center {
  display: flex;
  justify-content: center;
}
.hogwarts .justy-end {
  display: flex;
  justify-content: flex-end;
}
.hogwarts .justy-around {
  display: flex;
  justify-content: space-around;
}
.hogwarts .justy-between {
  display: flex;
  justify-content: space-between;
}
.hogwarts .justy-evenly {
  display: flex;
  justify-content: space-evenly;
}
.hogwarts .align-center{
  display: flex;
  align-items: center;
}
.hogwarts .title {
  font-size: 18px;
  font-weight: 700;
  color: #4c4c4c;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.hogwarts .sub-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 7px;
}
.hogwarts .desc {
  margin-top: 5px;
}
.fills {
  width: 320px;
  height: 1px;
  margin: 0 20px;
}
.hogwarts .card.vertical ~ .fills{
  width: 320px;
}
.hogwarts .card.horizontal ~ .fills{
  width: 400px;
}
</style>